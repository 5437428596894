import React, { useEffect, useState, useRef } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import MobileDetect from 'mobile-detect'
import FunnelPup from 'url:./img/funnelPup.svg'
import './css/content-styles.less'
import './css/main.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const appURLs = {
  dev: 'local.chatfunnels.dev:12345',
  beta: 'beta.chatfunnels.com',
  prod: 'app.chatfunnels.com'
}
const url = new URL(window.location.href)
const subdomain = url.hostname.split('.')[0]
const betaSubdomains = ['content', 'beta_content']

let env = 'prod'
let pathname = url.pathname
if (url.hostname === 'local.chatfunnels.dev') {
  env = 'dev'
} else if (betaSubdomains.includes(subdomain) || pathname.startsWith('/0/')) {
  // The hard-coded list of betaSubdomains is for backwards compatilibity. Moving forward, the
  // generated beta URLs will include a /0/ prefix, but just in case URLs without that
  // prefix have been distributed, this will allow the old urls to work
  env = 'beta'
  if (pathname.startsWith('/0/')) {
    pathname = pathname.substring(2)
  }
}
const isPreview = pathname === '/__preview'
const chatService = appURLs[env] + '/api/chat-service/a'
const allowedOrigin = `https://${appURLs[env]}`

function BotSidebar (props) {
  if (props.logoImage) {
    return (
      <div className='sidebar'>
        <div
          className='sidebar-header'
          style={{ cursor: props.useLogoUrl ? 'pointer' : 'default' }}
          onClick={() => {
            if (props.useLogoUrl && props.logoUrl) {
              window.location.href = props.logoUrl
            }
          }}
        >
          <img src={props.logoImage} className='logo' style={{ maxHeight: 100, maxWidth: 420 }} />
        </div>
        <div ref={props.chatClientRef} />
      </div>
    )
  }

  return (
    <div className='sidebar-no-header'>
      <div ref={props.chatClientRef} />
    </div>
  )
}

function determineBackgroundColor (content) {
  if (content?.background_type === 'solid') {
    document.body.style.backgroundColor = content?.background_color
    return
  }
  document.body.style.backgroundImage = `linear-gradient(to bottom right, ${content?.background_color}, ${content?.secondary_color})`
  document.body.style.backgroundAttachment = 'fixed'
}

function App (props) {
  const [logoImage, setLogoImage] = useState('')
  const [pdfContent, setPdfContent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [contentPageId, setContentPageId] = useState(null)
  const [errorState, setErrorState] = useState(false)
  const [content, setContent] = useState()
  const chatClientRef = useRef()
  const logoImageRef = useRef()
  const [maxPages, setMaxPages] = useState(1)
  const [botOnly, setBotOnly] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')
  const [useLogoUrl, setUseLogoUrl] = useState(false)

  const md = new MobileDetect(window.navigator.userAgent)
  const phone = !!md.phone()
  const width = window.screen.width - 20 > 900 ? 900 : window.screen.width - 20
  const numPagesRef = useRef()
  numPagesRef.current = maxPages
  logoImageRef.current = logoImage

  useEffect(() => {
    if (!isPreview) {
      const lookupUrl = `https://${chatService}/content_pages/quick_lookup`
      fetch(lookupUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({
          subdomain: subdomain,
          page: pathname.replace(/^\//, '')
        })
      })
        .then((response) => response.json())
        .then(response => {
          if (response.errors) {
            setErrorState(true)
          } else if (response.data) {
            setLoading(false)
            determineBackgroundColor(response.data.attributes)
            setLogoImage(response.data.attributes.logo_image)
            setLogoUrl(response.data.attributes.logo_url)
            setUseLogoUrl(response.data.attributes.use_logo_url)
            setPdfContent(response.data.attributes.pdf_content_url)
            setBotOnly(response.data.attributes.bot_only)
            window.document.title = response?.data?.attributes?.title
            setContent({ ...response.data.attributes, pdf_content_dataurl: 'data:application/pdf;base64,' + response.data.attributes.pdf_content })
          }
        })
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isPreview && contentPageId) {
      const lookupUrl = `https://${chatService}/content_pages/quick_lookup`
      fetch(lookupUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Pragma: 'no-cache'
        },
        body: JSON.stringify({
          subdomain: subdomain,
          page: contentPageId
        })
      })
        .then((response) => response.json())
        .then(response => {
          if (response.errors) {
            setErrorState(true)
          } else {
            window.document.title = response?.data?.attributes?.title
            setContent(response.data.attributes)
          }
        })
    }
  }, [contentPageId])

  useEffect(() => {
    window.addEventListener('message', (message) => {
      if (allowedOrigin !== message.origin) {
        return
      }
      const data = message.data
      if (data.command === 'update_iframe') {
        const values = data.params
        if (values.background_color) {
          document.body.style.backgroundColor = values.background_color
        }
        if (values.pdf_content) {
          setPdfContent(values.pdf_content)
        }
        if (values.content_page_id) {
          setContentPageId(values.content_page_id)
        }
        if (values.logo_image !== logoImageRef.current) {
          window.location.reload()
        }
      } else if (data.command === 'initialize_iframe') {
        const values = data.params
        document.body.style.backgroundColor = values.background_color
        setPdfContent(values.pdf_content)
        setContentPageId(values.content_page_id)
        setLogoImage(values.logo_image)
        logoImageRef.current = values.logo_image
      } else if (data.command === 'refresh') {
        window.location.reload()
      }
    })
    window.parent.postMessage({
      command: 'ready'
    }, '*')
  }, [])

  useEffect(() => {
    if (content) {
      const packetCode = content?.packet_code
      const loadPath = content?.load_path
      const servicePath = content?.service_path
      let logo

      if (isPreview) {
        logo = logoImage
      } else {
        logo = content.logo_image
      }

      if (packetCode) {
        let mode = 'content'
        if (logo && !botOnly) mode = 'content-with-header'
        else if (phone && !botOnly) mode = 'content-phone'
        else if (phone && botOnly) mode = 'landing-page'
        else if (botOnly) mode = 'landing-page'
        window.cf_chat_loader = () => {
          return {
            mode: mode,
            parentElement: chatClientRef.current,
            chatInitReady: (chatEl) => {
              if (phone) return
              if (logo && !botOnly) {
                chatEl.style.height = 'calc(100vh - 100px)'
                chatEl.style.width = 'calc(100% + 2px)'
              } else if (botOnly) {
                chatEl.style.height = '100%'
                chatEl.style.width = '100%'
              } else {
                chatEl.style.height = 'calc(100vh)'
                chatEl.style.width = 'calc(100% + 2px)'
              }
            },
            bot_id: content?.bot_id,
            packet_code: packetCode,
            app_url: `https://${loadPath}/chat-client/index.html`,
            service_address: servicePath
          }
        }

        const el = document.createElement('script')
        el.async = true
        el.src = `https://${loadPath}/chat-client/chat-loader.js`
        if (window.document.body) {
          window.document.body.appendChild(el)
        }
      }
    }
  }, [content]) //eslint-disable-line

  if (errorState) {
    return (
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={FunnelPup} style={{ height: 350 }} />
          </div>
          <div style={{ textAlign: 'center', fontSize: 50, color: '#b38cf7' }}>
            Sorry!
          </div>
          <div style={{ textAlign: 'center', fontSize: 22, color: '#333' }}>
            We couldn't find the page you were looking for.
          </div>
        </div>
      </div>
    )
  }
  if (botOnly) {
    return (
      <div className='main'>
        <div className={phone ? 'landing-container-phone' : 'landing-container'}>
          {logoImage && (
            <div
              className='landing-page-header'
              style={{ cursor: useLogoUrl ? 'pointer' : 'default' }}
              onClick={() => {
                if (useLogoUrl && logoUrl) {
                  window.location.href = logoUrl
                }
              }}
            >
              <img src={logoImage} className='logo' style={{ maxHeight: 200, maxWidth: 420 }} />
            </div>
          )}
          <div ref={chatClientRef} style={{ width: phone ? '100%' : 600, height: '100%' }} />
        </div>
      </div>
    )
  }
  if (loading) {
    return (<></>)
  }

  return (
    <div className='main' style={{ marginRight: phone ? 0 : 420 }}>
      <div className='pdfdoc'>
        <div className='pdfdoc__container' style={{ padding: phone ? 0 : null, margin: phone ? 0 : null }}>
          <div className='pdfdoc__container__document' style={{ padding: phone ? 0 : null, margin: phone ? 0 : null }}>
            {pdfContent
              ? (
                <i>
                  <Document
                    file={pdfContent}
                    onLoadSuccess={({ numPages }) => {
                      setMaxPages(numPages)
                    }}
                  >
                    {Array.from(
                      new Array(maxPages),
                      (_, index) => (
                        <Page
                          renderTextLayer
                          width={width}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      )
                    )}
                  </Document>
                </i>
              )
              : (
                <>
                  {isPreview
                    ? (
                      <>
                        <div style={{ backgroundColor: 'white', height: 700, width: 600, boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)', padding: 20 }}>
                          <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: 'block', marginTop: 'auto', marginBottom: 'auto' }}>
                              <h1>[<i> Upload your PDF </i>]</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <>

                        <div style={{ backgroundColor: 'white', height: 1164, width: 900, boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)', padding: 20 }}>
                          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', border: '3px dotted #B3B3B3', borderRadius: 10 }}>
                            <div style={{ display: 'block', marginTop: 'auto', marginBottom: 'auto' }}>
                              <h1>[ No PDF Found ]</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}

          </div>
        </div>
      </div>
      {!phone
        ? <BotSidebar chatClientRef={chatClientRef} logoImage={logoImage} useLogoUrl={useLogoUrl} logoUrl={logoUrl} />
        : <div ref={chatClientRef} />}
    </div>
  )
}
export default App
