// import another component
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from '../App.jsx'

/*
import * as Sentry from '@sentry/browser'
let env = 'dev'
if (window.location.hostname === 'beta.chatfunnels.com') {
    env = 'beta'
} else if (window.location.hostname === 'app.chatfunnels.com') {
    env = 'prod'
}

Sentry.init({
    dsn: 'https://583456986bf54409aa75b2535277bfa9@o265885.ingest.sentry.io/5219986',
    environment: env,
    release: 'v1.4.11'
})
*/

ReactDOM.render(<App />, document.getElementById('root')) // eslint-disable-line
